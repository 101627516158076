import React, { useEffect, useState } from 'react';
import '../index.css';
import '../business.css';

const nodevinConsoleImage = 'images/console-command.png';

const BusinessPage = () => {
  return (
    <div className="App">
      <div className="container">
        <header className="App-header">
          <h1>nodevin</h1>
          <p className="business-label">business</p>
          <p>Your blockchain, accessible anywhere, one command.</p>
          <div className='App-sub-header-text'>
            <p>Empower your blockchain network with global accessibility and decentralization with Nodevin.</p>
          </div>
        </header>
        <main>
          <h2>Business Pricing</h2>
          <p className="App-sub-header-text" style={{"marginTop": "5px"}}>Save your business time, effort, and money</p>
          <section className="pricing-options">
            <div className="pricing-box">
              <h2 style={{"color": "#2d5b86"}}>Mini Grant</h2>
              <p style={{"marginTop": "5px"}}><span style={{"color": "#2d5b86"}} className="price-label">$10,000 USD</span></p>
              <hr width="100%"/>
              <p><i className="smaller">Your software will be integrated into Nodevin's open-source codebase.</i>
                <ul className="features-list">
                  <li>Single command node startup</li>
                  <li>Automatically update running nodes</li>
                  <li>Configurable with docker images</li>
                  <li>Compatible with any operating system</li>
                </ul>
              </p>
              <hr width="100%"/>
              <p><i className="smaller">We accept your token as payment!</i></p>
            </div>
            <div className="pricing-box">
              <h2>Mega Grant</h2>
              <p style={{"marginTop": "5px"}}><span className="price-label">$25,000 USD</span></p>
              <hr width="100%"/>
              <p><i className="smaller">For larger software support and greater accessibility inside and outside of Nodevin.</i>
                <ul className="features-list">
                  <li>90% faster sync times</li>
                  <li>Universally available data snapshots</li>
                  <li>Built in open-source Docker images</li>
                  <li>Latest image versioning</li>
                </ul>
              </p>
              <hr width="100%"/>
              <p><i className="smaller">Everything Nodevin has to offer.</i></p>
            </div>
          </section>

          <section className="console-image-section">
            <h2>Your Software, One Command</h2>
            <p className="App-sub-header-text" style={{"marginTop": "5px"}}>A single step away from <i>nodevin start {"<"}you{">"}</i></p>
            <br/>
            <img src={nodevinConsoleImage} alt="nodevin-console" style={{"width": "100%"}}/>
          </section>

          {/* Comparison Table Section */}
          <section className="comparison-section">
            <h2 style={{"marginBottom": "0px"}}>Compare Nodevin</h2>
            <p className="App-sub-header-text" style={{"marginTop": "5px"}}>Blockchain accessibility made cheaper and easier</p>
            <br/>
            <table className="comparison-table">
              <thead>
                <tr>
                  <th>Features</th>
                  <th style={{"fontWeight": 400, "fontStyle": "italic"}}>Other Node Providers</th>
                  <th style={{"fontWeight": 400, "fontStyle": "italic"}}>Running a Node Yourself</th>
                  <th>Nodevin Mini Grant</th>
                  <th>Nodevin Mega Grant</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Multiple blockchain support</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Easy setup</td>
                  <td className="green">✔</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Automated updates</td>
                  <td className="green">✔</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Full control over data</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Customizable security</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Fully open-source</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Cross-platform support</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Universal data snapshots</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Open-source Docker images</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Snapshot synchronization</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                </tr>
                <tr>
                  <td>Cost-effective for scaling</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="red">✘</td>
                  <td className="green">✔</td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className="call-to-action">
            <h2>Ready to Get Started?</h2>
            <p>
              Sponsor your blockchain's integration today to ensure that your network benefits from global decentralization and greater accessibility. Our goal is your network's health.
            </p>
            <a href="mailto:business@nodevin.xyz" className="cta-button">Contact Us</a>
          </section>

          <br />
          <br />
          <br />
          <br />
          <div className='App-sub-header-business-text'>
            <i><a rel="noopener noreferrer" href="/">Home</a></i>
          </div>
          <br />
          <br />
          <br />
          <br />
        </main>
      </div>
      <footer className="App-footer">
        <div className="container">
          <span>Brought to you by <strong><a href="https://fiftysix.xyz" rel="noopener noreferrer" target="_blank" className='App-footer-a'>Fiftysix</a></strong></span>
        </div>
      </footer>
    </div>
  );
}

export default BusinessPage;
