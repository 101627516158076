import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../index.css';

const windowsLogo = 'images/windows.png';
const macLogo = 'images/apple.png';
const linuxLogo = 'images/linux.png';

const HomePage = () => {
  const [versions, setVersions] = useState({
    windows: { amd64: [], arm64: [] },
    mac: { amd64: [], arm64: [] },
    linux: { amd64: [], arm64: [] },
  });

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await axios.get('https://api.github.com/repos/fiftysixcrypto/nodevin/releases');
        const releases = response.data;

        const versionData = {
          windows: { amd64: [], arm64: [] },
          mac: { amd64: [], arm64: [] },
          linux: { amd64: [], arm64: [] },
        };

        releases.forEach(release => {
          const version = release.tag_name;

          release.assets.forEach(asset => {
            if (asset.name.endsWith('.zip') || asset.name.endsWith('.gz')) {
              if (asset.name.includes('windows')) {
                if (asset.name.includes('amd64')) {
                  versionData.windows.amd64.push({ version, url: asset.browser_download_url });
                } else if (asset.name.includes('arm64')) {
                  versionData.windows.arm64.push({ version, url: asset.browser_download_url });
                }
              } else if (asset.name.includes('mac')) {
                if (asset.name.includes('amd64')) {
                  versionData.mac.amd64.push({ version, url: asset.browser_download_url });
                } else if (asset.name.includes('arm64')) {
                  versionData.mac.arm64.push({ version, url: asset.browser_download_url });
                }
              } else if (asset.name.includes('linux')) {
                if (asset.name.includes('amd64')) {
                  versionData.linux.amd64.push({ version, url: asset.browser_download_url });
                } else if (asset.name.includes('arm64')) {
                  versionData.linux.arm64.push({ version, url: asset.browser_download_url });
                }
              }
            }
          });
        });

        setVersions(versionData);
      } catch (error) {
        console.error('Error fetching versions:', error);
      }
    };

    fetchVersions();
  }, []);

  return (
    <div className="App">
      <div className="container">
        <header className="App-header">
          <h1>nodevin</h1>
          <p>We developed nodevin so you could support decentralization.</p>
          <div className='App-sub-header-text'>
            <p>nodevin is a CLI and daemon that starts and manages blockchain nodes. <a rel="noopener noreferrer" target="_blank" href="https://github.com/fiftysixcrypto/nodevin">Code here.</a></p>
          </div>
          <div className='App-sub-header-business-text'>
            <i><a rel="noopener noreferrer" href="/#/business">Business? Click here.</a></i>
          </div>
        </header>
        <main>
          <section className="latest-versions">
            <div className="os-box">
              <img src={windowsLogo} alt="Windows" />
              <h2>Windows</h2>
              <p>Latest Version: <a href={versions.windows.amd64[0]?.url} target="_blank" rel="noopener noreferrer">{versions.windows.amd64[0]?.version}</a></p>
              <div className='App-sub-header-business-text'>
                <p><i>ARM64:</i> <a href={versions.windows.arm64[0]?.url} target="_blank" rel="noopener noreferrer">{versions.windows.arm64[0]?.version}</a></p>
              </div>
            </div>
            <div className="os-box">
              <img src={macLogo} alt="Mac" />
              <h2>Mac</h2>
              <p>Latest Version: <a href={versions.mac.amd64[0]?.url} target="_blank" rel="noopener noreferrer">{versions.mac.amd64[0]?.version}</a></p>
              <div className='App-sub-header-business-text'>
                <p><i>ARM64:</i> <a href={versions.mac.arm64[0]?.url} target="_blank" rel="noopener noreferrer">{versions.mac.arm64[0]?.version}</a></p>
              </div>
            </div>
            <div className="os-box">
              <img src={linuxLogo} alt="Linux" />
              <h2>Linux</h2>
              <p>Latest Version: <a href={versions.linux.amd64[0]?.url} target="_blank" rel="noopener noreferrer">{versions.linux.amd64[0]?.version}</a></p>
              <div className='App-sub-header-business-text'>
                <p><i>ARM64:</i> <a href={versions.linux.arm64[0]?.url} target="_blank" rel="noopener noreferrer">{versions.linux.arm64[0]?.version}</a></p>
              </div>
            </div>
          </section>
          <br />
          <div className='App-sub-header-business-text'>
            <i><a rel="noopener noreferrer" href="https://github.com/fiftysixcrypto/nodevin#nodevin">Documentation here.</a></i>
          </div>
          <br />
          <div>
            <img className="nodevin-image" src="nodevin-icon.png" alt="nodevin-icon" />
          </div>
          <br />
          <p className="smaller-text"><em>Join our <a rel="noopener noreferrer" target="_blank" href="https://discord.com/invite/XuhW2ykW3D">Discord</a>.</em></p>
          <br />
          <p>"The network is robust in its unstructured simplicity." -- Satoshi Nakamoto</p>
          <br />
        </main>
      </div>
      <footer className="App-footer">
        <div className="container">
          <span>Brought to you by <strong><a href="https://fiftysix.xyz" rel="noopener noreferrer" target="_blank" className='App-footer-a'>Fiftysix</a></strong></span>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;


/*
          <section>
            <div>
              <div className="wrapper">
                <div className="support-container">
                  <div className="logo-container">
                    <div className="logo-fest">
                      <img src="images/bitcoin-logo.png" alt="Bitcoin"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/litecoin-logo.png" alt="Litecoin"/>
                    </div>
                </div>
              </div>
              </div>
            </div>
          </section>
                    <div className="logo-fest">
                      <img src="images/ethereum-logo.png" alt="Ethereum"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/dogecoin-logo.png" alt="Dogecoin"/>
                    </div>
                    <div className="logo-fest">
                      <img src="images/ethereum-classic-logo.png" alt="Eth-Classic"/>
                    </div>
*/
